import React from 'react';
import Container from 'rsuite/Container';

function TableError({
    mainTitle = 'Oops, An Unexpected Error Occurred',
    subtitle,
    showMainTitle = true,
    color = 'white',
}) {
    return (
        <Container>
            <div
                style={{
                    height: '95px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 10px',
                    color: color,
                    border: '1.5px solid gray',
                }}
            >
                {showMainTitle && (
                    <h6 style={{ fontWeight: 'bold', textAlign: 'center', color: '#ffc400' }}>
                        {mainTitle}
                    </h6>
                )}

                <p
                    style={{
                        textAlign: 'center',
                        color: color,
                        marginBottom: '10px',
                    }}
                >
                    {subtitle}
                </p>
            </div>
        </Container>
    );
}

export default TableError;
