import axios from 'axios';
import toast from 'react-hot-toast';
import {
    CASHOUT,
    checkExpiredUserToken,
    getUserToken,
    KENO,
    MEGA_7,
    META_4,
    META_4_BETOPTIONS,
    METRO,
    PERFECT_BALLS,
    PERFECT_BALLS_BETOPTIONS,
    safeJSONParse,
    SALARY_FOR_LIFE,
    SEVEN49,
    SIX_FOUR_NINE,
    POOL_OF_FAME,
    POOL_OF_FAME_BETOPTIONS,
    AFRIKENO_OVEROPTIONS,
    LOTTO,
    LOTTO_BETOPTIONS,
    LOTTO_RESULTOPTIONS,
    LOTTO_OVEROPTIONS,
    LOTTO_UNDEROPTIONS,
    SELECTED_LOTTERY_BETOPTIONS,
    SELECTED_BETOPTION,
    ACTIVE_GAME,
    FIVE_0F_NINETY,
    MARIO_KENO,
    MARIO_KENO_BETOPTIONS,
    LOTTO_CONTINENTAL,
    LOTTO_CONTINENTAL_BET_OPTIONS,
    BINGO_BALLS,
    BINGO_BALLS_BETOPTIONS,
    LOTTO_CONTINENTAL_RESULT_OPTIONS,
    GHANA_GAME,
    GHANA_RESULT_OPTIONS,
    SEVEN49_BETOPTIONS,
    CASHOUT_BETOPTIONS,
    METRO_BETOPTIONS,
    SIX_FOUR_NINE_BETOPTIONS,
    KENO_BETOPTIONS,
    AFRIKENO_BETOPTIONS,
    EAZY_WIN_BETOPTIONS,
    MEGA_7_BETOPTIONS,
    LEGENDARY_LOTTO_BETOPTIONS,
    SALARY_FOR_LIFE_BETOPTIONS,
    FIVE_0F_NINETY_BETOPTIONS,
    EAZY_WIN_OVEROPTIONS,
    EAZY_WIN_UNDEROPTIONS,
    METRO_UNDEROPTIONS,
    METRO_OVEROPTIONS,
    getWindowQueryString,
    ALL_LOTTERIES_BETOPTIONS,
    safeJSONParseObj,
} from 'utils';
import {
    SET_LOTTERY_CATEGORY,
    SET_BET_OPTIONS,
    SET_RESULT_OPTIONS,
    SET_OVER_OPTIONS,
    SET_UNDER_OPTIONS,
    SET_BOOSTER_OPTIONS,
    SET_BETTYPE_DESCRIPTION,
    SET_POTENTIAL_WINNING_DATA,
    RESET_BET_SLIPS,
    REMOVE_BET_SLIP,
    SET_GAME_BET_OPTION,
    SET_GAME_RESULT_OPTION,
    SET_GAME_OVER_OPTION,
    SET_GAME_UNDER_OPTION,
    SELECT_GAME,
    UNSELECT_GAME,
    SET_COMBO_DESCRIPTION,
    UPDATE_IS_ADDING_BETSLIPS,
    UPDATE_BET_SLIPS,
    RESET_COMBO_DESCRIPTION,
    SET_SELECTED_OVER_OPTION_OBJ,
    SET_SELECTED_UNDER_OPTION_OBJ,
    RESET_SELECTED_OVER_OPTION_OBJ,
    RESET_SELECTED_UNDER_OPTION_OBJ,
} from '../types/types';
import { EAZY_WIN, AFRIKENO } from 'utils';
import { handleGetAllBetOptions } from './lotteryActions2';
import {
    EazywinAditionalOptions,
    KenoAditionalOptions,
    MetroAditionalOptions,
    Six49AditionalOptions,
} from 'utils/additionalOptions';
export const EMPTY_STRING = '';
export const SESSION_STORAGE_CATEGORY = 'SESSION_STORAGE_CATEGORY';

export const handleSetLotteryCategory = (category) => (dispatch) => {
    if (typeof window === 'object') {
        sessionStorage.setItem(SESSION_STORAGE_CATEGORY, category);
    }
    return dispatch({
        type: SET_LOTTERY_CATEGORY,
        payload: {
            lotteryCategory: category,
        },
    });
};
export const handleGetLotteryCategory = () => (dispatch) => {
    let category;
    if (typeof window === 'object') {
        category = sessionStorage.getItem(SESSION_STORAGE_CATEGORY);
    }

    if (category) {
        dispatch({
            type: SET_LOTTERY_CATEGORY,
            payload: {
                lotteryCategory: category,
            },
        });
    }
    return category;
};

// Action to get the bet options based on the lottery category selected
export function handleGetBetOptions(category) {
    let params = '';
    if (typeof window !== 'undefined') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        params = Object.fromEntries(urlSearchParams.entries());
    }
    return async (dispatch, getState) => {
        try {
            const lotteryCategory =
                dispatch(handleGetLotteryCategory()) || category || params?.lotteryCategory;

            let betOptionSlug;

            if (lotteryCategory === FIVE_0F_NINETY) {
                betOptionSlug = FIVE_0F_NINETY_BETOPTIONS;
            } else if (lotteryCategory === SALARY_FOR_LIFE) {
                betOptionSlug = SALARY_FOR_LIFE_BETOPTIONS;
            } else if (
                lotteryCategory === 'legendarylotto' ||
                lotteryCategory === 'legendary-lotto'
            ) {
                betOptionSlug = LEGENDARY_LOTTO_BETOPTIONS;
            } else if (lotteryCategory === MEGA_7) {
                betOptionSlug = MEGA_7_BETOPTIONS;
            } else if (lotteryCategory === EAZY_WIN) {
                betOptionSlug = EAZY_WIN_BETOPTIONS;
            } else if (lotteryCategory === AFRIKENO) {
                betOptionSlug = AFRIKENO_BETOPTIONS;
            } else if (lotteryCategory === KENO) {
                betOptionSlug = KENO_BETOPTIONS;
            } else if (lotteryCategory === SIX_FOUR_NINE) {
                betOptionSlug = SIX_FOUR_NINE_BETOPTIONS;
            } else if (lotteryCategory === CASHOUT) {
                betOptionSlug = CASHOUT_BETOPTIONS;
            } else if (lotteryCategory === META_4) {
                betOptionSlug = META_4_BETOPTIONS;
            } else if (lotteryCategory === PERFECT_BALLS) {
                betOptionSlug = PERFECT_BALLS_BETOPTIONS;
            } else if (lotteryCategory === METRO) {
                betOptionSlug = METRO_BETOPTIONS;
            } else if (lotteryCategory === SEVEN49) {
                betOptionSlug = SEVEN49_BETOPTIONS;
            } else if (lotteryCategory === POOL_OF_FAME) {
                betOptionSlug = POOL_OF_FAME_BETOPTIONS;
            } else if (lotteryCategory === LOTTO) {
                betOptionSlug = LOTTO_BETOPTIONS;
            } else if (lotteryCategory === MARIO_KENO) {
                betOptionSlug = MARIO_KENO_BETOPTIONS;
            } else if (lotteryCategory === LOTTO_CONTINENTAL) {
                betOptionSlug = LOTTO_CONTINENTAL_BET_OPTIONS;
            } else if (lotteryCategory === BINGO_BALLS) {
                betOptionSlug = BINGO_BALLS_BETOPTIONS;
            }
            // console.log('hhhhhhhhhhhhh', betOptionSlug);
            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${betOptionSlug}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            let content = safeJSONParse(res.data?.data?.data?.content);
            if (lotteryCategory === KENO) {
                content = [...content, ...KenoAditionalOptions];
            }
            if (lotteryCategory === SIX_FOUR_NINE) {
                content = [...content, ...Six49AditionalOptions];
            }
            if (lotteryCategory === METRO) {
                content = [...content, ...MetroAditionalOptions];
            }
            if (lotteryCategory === EAZY_WIN) {
                content = [...content, ...EazywinAditionalOptions];
            }

            let newContent = {};

            content.forEach((element) => {
                newContent[element.value] = element;
            });
            // console.log('new content', newContent);
            typeof window !== 'undefined' &&
                sessionStorage.setItem(SELECTED_LOTTERY_BETOPTIONS, JSON.stringify(newContent));
            dispatch({
                type: SET_BET_OPTIONS,
                payload: newContent,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}

// Action to get the result options based on the lottery category selected
export function handleGetResultOptions(data) {
    return async (dispatch, getState) => {
        try {
            const lotteryCategory = dispatch(handleGetLotteryCategory());
            let resultOptionSlug;

            if (lotteryCategory === FIVE_0F_NINETY) {
                resultOptionSlug = 'five-ninety-result-types';
            } else if (
                lotteryCategory === SALARY_FOR_LIFE ||
                lotteryCategory === MEGA_7 ||
                lotteryCategory === EAZY_WIN ||
                lotteryCategory === AFRIKENO ||
                lotteryCategory === KENO ||
                lotteryCategory === POOL_OF_FAME
            ) {
                return dispatch({
                    type: SET_RESULT_OPTIONS,
                    payload: null,
                });
            } else if (
                lotteryCategory === 'legendarylotto' ||
                lotteryCategory === 'legendary-lotto'
            ) {
                resultOptionSlug = 'legendary-lotto-result-types';
            } else if (lotteryCategory === LOTTO) {
                resultOptionSlug = LOTTO_RESULTOPTIONS;
            } else if (lotteryCategory === LOTTO_CONTINENTAL) {
                resultOptionSlug = LOTTO_CONTINENTAL_RESULT_OPTIONS;
            } else if (lotteryCategory === GHANA_GAME) {
                resultOptionSlug = GHANA_RESULT_OPTIONS;
            }

            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${resultOptionSlug}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            let content = safeJSONParse(res.data?.data?.data?.content);
            let newContent = {};

            content.forEach((element) => {
                newContent[element.value] = element;
            });
            // console.log('new content', newContent);
            dispatch({
                type: SET_RESULT_OPTIONS,
                payload: newContent,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}

// Action to get the booster options based on the lottery category selected and save to redux store
export function handleGetBoosterOptions(data) {
    return async (dispatch, getState) => {
        try {
            const lotteryCategory = dispatch(handleGetLotteryCategory());
            let resultOptionSlug;

            if (lotteryCategory === FIVE_0F_NINETY) {
                resultOptionSlug = 'five-ninety-booster-options';
            } else if (
                lotteryCategory === SALARY_FOR_LIFE ||
                lotteryCategory === MEGA_7 ||
                lotteryCategory === EAZY_WIN ||
                lotteryCategory === AFRIKENO ||
                lotteryCategory === KENO ||
                lotteryCategory === POOL_OF_FAME
            ) {
                return dispatch({
                    type: SET_BOOSTER_OPTIONS,
                    payload: null,
                });
            } else if (
                lotteryCategory === 'legendarylotto' ||
                lotteryCategory === 'legendary-lotto'
            ) {
                resultOptionSlug = 'legendary-lotto-booster-options';
            }

            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${resultOptionSlug}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            let content = safeJSONParse(res.data?.data?.data?.content);
            let newContent = {};

            content.forEach((element) => {
                newContent[element.value] = element;
            });
            // console.log('new content', newContent);
            dispatch({
                type: SET_BOOSTER_OPTIONS,
                payload: newContent,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}

// Action to get the over options based on the lottery category selected
export function handleGetOverOptions() {
    return async (dispatch, getState) => {
        try {
            const lotteryCategory = dispatch(handleGetLotteryCategory());
            let resultOptionSlug;

            if (
                lotteryCategory === FIVE_0F_NINETY ||
                lotteryCategory === 'legendarylotto' ||
                lotteryCategory === 'legendary-lotto'
            ) {
                resultOptionSlug = 'over-bet-options';
            }
            if (lotteryCategory === AFRIKENO) {
                resultOptionSlug = AFRIKENO_OVEROPTIONS;
            }
            if (lotteryCategory === LOTTO) {
                resultOptionSlug = LOTTO_OVEROPTIONS;
            }
            if (lotteryCategory === EAZY_WIN) {
                resultOptionSlug = EAZY_WIN_OVEROPTIONS;
            }
            if (lotteryCategory === METRO) {
                resultOptionSlug = METRO_OVEROPTIONS;
            }
            if (lotteryCategory === SALARY_FOR_LIFE || lotteryCategory === MEGA_7) {
                return dispatch({
                    type: SET_OVER_OPTIONS,
                    payload: null,
                });
            }
            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${resultOptionSlug}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            let content = safeJSONParse(res.data?.data?.data?.content);
            let newContent = {};

            content.forEach((element) => {
                newContent[element.value] = element;
            });
            dispatch({
                type: SET_OVER_OPTIONS,
                payload: newContent,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}

// Action to get the over options based on the lottery category selected
export function handleGetUnderOptions() {
    return async (dispatch, getState) => {
        try {
            const lotteryCategory = dispatch(handleGetLotteryCategory());
            let resultOptionSlug;

            if (
                lotteryCategory === FIVE_0F_NINETY ||
                lotteryCategory === 'legendarylotto' ||
                lotteryCategory === 'legendary-lotto'
            ) {
                resultOptionSlug = 'under-bet-options';
            }
            if (lotteryCategory === LOTTO) {
                resultOptionSlug = LOTTO_UNDEROPTIONS;
            }
            if (lotteryCategory === EAZY_WIN) {
                resultOptionSlug = EAZY_WIN_UNDEROPTIONS;
            }
            if (lotteryCategory === METRO) {
                resultOptionSlug = METRO_UNDEROPTIONS;
            }
            if (lotteryCategory === SALARY_FOR_LIFE || lotteryCategory === MEGA_7) {
                return dispatch({
                    type: SET_UNDER_OPTIONS,
                    payload: null,
                });
            }
            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${resultOptionSlug}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            let content = safeJSONParse(res.data?.data?.data?.content);
            let newContent = {};

            content.forEach((element) => {
                newContent[element.value] = element;
            });
            dispatch({
                type: SET_UNDER_OPTIONS,
                payload: newContent,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}

// Action to set the game bet options based on the lottery category selected
export function handleSetGameBetOption(option) {
    return async (dispatch, getState) => {
        if (!option) {
            return;
        }
        const { lotteryData } = getState();
        if (option === EMPTY_STRING) {
            typeof window !== 'undefined' && sessionStorage.removeItem(SELECTED_BETOPTION);
            return dispatch({
                type: SET_GAME_BET_OPTION,
                payload: {},
            });
        }

        const lotteryCategory = getWindowQueryString('lotteryCategory');
        let allBetOptions = sessionStorage.getItem(ALL_LOTTERIES_BETOPTIONS);
        console.log('all bet Options', allBetOptions[lotteryCategory]);
        if (!allBetOptions) {
            await dispatch(handleGetAllBetOptions());
            allBetOptions = sessionStorage.getItem(ALL_LOTTERIES_BETOPTIONS);
        }
        allBetOptions = safeJSONParseObj(allBetOptions);
        let backupOption;
        if (allBetOptions[lotteryCategory] && allBetOptions[lotteryCategory][option]) {
            backupOption = allBetOptions[lotteryCategory][option];
        }
        let backup2Option = {};
        if (lotteryData?.lotteryBetOptions) {
            backup2Option = lotteryData?.lotteryBetOptions[option];
        }
        let betOption = lotteryData?.allLotteryBetOptions[lotteryCategory]
            ? lotteryData?.allLotteryBetOptions[lotteryCategory][option]
            : null;
        if (!betOption && backup2Option) {
            betOption = backup2Option;
        }
        // console.log('lottery category', lotteryData?.allLotteryBetOptions[lotteryCategory]);
        // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxx', backup2Option);
        dispatch({
            type: SET_BETTYPE_DESCRIPTION,
            payload: betOption?.description ? betOption?.description : backupOption?.description,
        });
        typeof window !== 'undefined' &&
            sessionStorage.setItem(SELECTED_BETOPTION, JSON.stringify(betOption));
        // return dispatch({
        //     type: SET_GAME_BET_OPTION,
        //     payload: betOption?.value ? betOption : backupOption,
        // });
        return await dispatch({
            type: SET_GAME_BET_OPTION,
            payload: betOption,
        });
    };
}

// Action to set the game over bet option, this is mainly for the advance over options
export function handleSetGameAdvanceOverOption(option) {
    return async (dispatch, getState) => {
        if (!option) {
            return;
        }
        await dispatch({
            type: RESET_SELECTED_UNDER_OPTION_OBJ,
        });
        return await dispatch({
            type: SET_SELECTED_OVER_OPTION_OBJ,
            payload: option,
        });
    };
}
// Action to set the game under bet option, this is mainly for the advance under options
export function handleSetGameAdvanceUnderOption(option) {
    return async (dispatch, getState) => {
        if (!option) {
            return;
        }
        await dispatch({
            type: RESET_SELECTED_OVER_OPTION_OBJ,
        });
        return await dispatch({
            type: SET_SELECTED_UNDER_OPTION_OBJ,
            payload: option,
        });
    };
}

// Action to reset the game over bet option, this is mainly for the advance over options
export function handleResetGameAdvanceOverOption() {
    return async (dispatch, getState) => {
        return await dispatch({
            type: RESET_SELECTED_OVER_OPTION_OBJ,
        });
    };
}
// Action to reset the game under bet option, this is mainly for the advance under options
export function handleResetGameAdvanceUnderOption() {
    return async (dispatch, getState) => {
        return await dispatch({
            type: RESET_SELECTED_UNDER_OPTION_OBJ,
        });
    };
}

// Action to reset the game bet type description
export function handleResetGameBetType() {
    return async (dispatch, getState) => {
        return dispatch({
            type: SET_BETTYPE_DESCRIPTION,
            payload: '',
        });
    };
}

// Action to set the game result options based on the lottery category selected
export function handleSetGameResultOption(option) {
    return async (dispatch, getState) => {
        const { lotteryData } = getState();
        if (option === EMPTY_STRING) {
            return dispatch({
                type: SET_GAME_RESULT_OPTION,
                payload: {},
            });
        }

        const betOption = lotteryData?.lotteryResultOptions[option];
        // dispatch({ type: SET_BETTYPE_DESCRIPTION, payload: betOption.description });
        return dispatch({
            type: SET_GAME_RESULT_OPTION,
            payload: betOption,
        });
    };
}

// Action to set the game over option
export function handleSetGameOverOption(option) {
    return async (dispatch, getState) => {
        const { lotteryData } = getState();
        if (option === EMPTY_STRING) {
            return dispatch({
                type: SET_GAME_OVER_OPTION,
                payload: {},
            });
        }

        const betOption = lotteryData?.lotteryOverOptions[option];
        dispatch({ type: SET_BETTYPE_DESCRIPTION, payload: betOption?.description });
        return dispatch({
            type: SET_GAME_OVER_OPTION,
            payload: betOption,
        });
    };
}

// Action to set the game under option
export function handleSetGameUnderOption(option) {
    return async (dispatch, getState) => {
        const { lotteryData } = getState();
        if (option === EMPTY_STRING) {
            return dispatch({
                type: SET_GAME_UNDER_OPTION,
                payload: {},
            });
        }

        const betOption = lotteryData?.lotteryUnderOptions[option];
        dispatch({ type: SET_BETTYPE_DESCRIPTION, payload: betOption?.description });

        return dispatch({
            type: SET_GAME_UNDER_OPTION,
            payload: betOption,
        });
    };
}

// Action to set the game bet type description
export function handleSetGameBetTypeDesc(message) {
    return async (dispatch, getState) => {
        return dispatch({
            type: SET_BETTYPE_DESCRIPTION,
            payload: message,
        });
    };
}
// Action to set the game selected
export function handleSelectGame(selectedGame) {
    if (typeof window === 'object') {
        sessionStorage.setItem(ACTIVE_GAME, JSON.stringify(selectedGame));
    }
    return async (dispatch) => {
        return dispatch({
            type: SELECT_GAME,
            payload: selectedGame,
        });
    };
}

// Action to unselect a game
export function handleUnselectGame() {
    return async (dispatch) => {
        return dispatch({
            type: UNSELECT_GAME,
            payload: {},
        });
    };
}

// Action to set or update the betslips and also calls the potential winning endpoint
export function handleUpdateBetSlips(newSlip, lotteryId, clientId) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: true });
            const { lotteryData } = getState();
            const slips = lotteryData?.betSlips;
            const newSlips = [...slips, newSlip];
            let potentialPayload = {};
            potentialPayload.lotteryId = lotteryId;
            potentialPayload.betSlips = JSON.stringify(newSlips);

            const token = getUserToken();
            let headers = {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            };
            if (clientId) {
                headers['x-client-id'] = clientId;
            }
            const res = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/get-potential-winning`,
                potentialPayload,
                {
                    headers,
                },
            );

            dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });
            dispatch({ type: UPDATE_BET_SLIPS, payload: newSlips });
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            return toast.success('Ticket Added Successfully');
        } catch (error) {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
            console.log('eroror', error);
        }
    };
}

// Action to set or update the betslips and also calls the potential winning endpoint
export function handleUpdateClientBetSlips(newSlip, lotteryId) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: true });
            const { lotteryData } = getState();
            const slips = lotteryData?.betSlips;
            const newSlips = [...slips, newSlip];
            let potentialPayload = {};
            potentialPayload.lotteryId = lotteryId;
            potentialPayload.betSlips = JSON.stringify(newSlips);
            const clientKey = getWindowQueryString('clientKey');

            let headers = {
                'x-api-key': clientKey,
            };

            const res = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/tenant/get-potential-winning`,
                potentialPayload,
                {
                    headers,
                },
            );

            dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });
            dispatch({ type: UPDATE_BET_SLIPS, payload: newSlips });
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            return toast.success('Ticket Added Successfully');
        } catch (error) {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
            console.log('eroror', error);
        }
    };
}
// Action to set or update the betslips and also calls the potential winning endpoint
export function handleUpdateRandomBetSlips(newSlip, lotteryId) {
    return async (dispatch, getState) => {
        // checkExpiredUserToken();
        try {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: true });
            // console.log('new slip', newSlip);
            const { lotteryData } = getState();
            const slips = lotteryData?.betSlips;
            const newSlips = [...slips, ...newSlip];
            let potentialPayload = {};
            potentialPayload.lotteryId = lotteryId;
            potentialPayload.betSlips = JSON.stringify(newSlips);
            // checkExpiredUserToken();
            const token = getUserToken();
            const res = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/get-potential-winning`,
                potentialPayload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            await dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });
            await dispatch({ type: UPDATE_BET_SLIPS, payload: newSlips });
            await dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            return toast.success('Ticket Added Successfully');
        } catch (error) {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
            console.log('eroror', error);
        }
    };
}

// Action to update betslips for client games
export function handleClientUpdateRandomBetSlips(newSlip, lotteryId) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: true });
            // console.log('new slip', newSlip);
            const { lotteryData } = getState();
            const slips = lotteryData?.betSlips;
            const newSlips = [...slips, ...newSlip];
            let potentialPayload = {};
            potentialPayload.lotteryId = lotteryId;
            potentialPayload.betSlips = JSON.stringify(newSlips);
            const clientKey = getWindowQueryString('clientKey');

            const res = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/tenant/get-potential-winning`,
                potentialPayload,
                {
                    headers: {
                        'x-api-key': clientKey,
                    },
                },
            );

            await dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });
            await dispatch({ type: UPDATE_BET_SLIPS, payload: newSlips });
            await dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            return toast.success('Ticket Added Successfully');
        } catch (error) {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
            console.log('eroror', error);
        }
    };
}

// Action to set betslips from an existing played ticket
export function handleSetBetSlipsFromTicket(newSlip, lotteryId, gameId) {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: true });
            const { lotteryData, userData } = getState();
            let potentialPayload = {};
            potentialPayload.lotteryId = lotteryId;
            potentialPayload.betSlips = JSON.stringify(newSlip);
            checkExpiredUserToken();
            const token = getUserToken();
            // const res = await axios.post(
            //     `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/get-potential-winning`,
            //     potentialPayload,
            //     {
            //         headers: {
            //             Authorization: `Bearer ${token}`,
            //             'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            //         },
            //     },
            // );

            //  console.log(
            //     ' res?.data?.data?.data res?.data?.data?.data',
            //     res?.data?.data?.data,
            // );
            let ticketPayload = {};
            // ticketPayload.betSlips = res?.data?.data?.data?.betSlips;
            // ticketPayload.linesCount = res?.data?.data?.data?.totalSlips;
            // ticketPayload.totalStakedAmount = res?.data?.data?.data?.totalStakedAmount;
            // ticketPayload.sourceWallet = 'mainWallet';
            // ticketPayload.gameId = gameId;

            ticketPayload.betSlips = newSlip?.betSlips;
            ticketPayload.linesCount = newSlip?.linesCount;
            ticketPayload.totalStakedAmount = Number(newSlip?.totalStakedAmount);
            ticketPayload.sourceWallet = 'mainWallet';
            ticketPayload.gameId = gameId;

            if (userData?.userData?.isAgent) {
                if (userData?.userData?.role === 'cashier') {
                    ticketPayload.winningRedemptionMethod = 'dps';
                } else if (userData?.walletInformation?.accountNumber) {
                    ticketPayload.winningRedemptionMethod = 'bank';
                    ticketPayload.bankDetails = JSON.stringify({
                        bankCode: userData.walletInformation?.bankCode,
                        accountNumber: userData.walletInformation?.accountNumber,
                        accountName: userData.walletInformation?.accountName,
                    });
                } else {
                    ticketPayload.winningRedemptionMethod = 'dps';
                }
            } else {
                ticketPayload.winningRedemptionMethod = 'wallet';
            }
            const ticketResult = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/create-ticket`,
                ticketPayload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            toast.success('Ticket Created Successfully');

            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            return { success: true, data: ticketResult?.data?.data?.data };
        } catch (error) {
            dispatch({ type: UPDATE_IS_ADDING_BETSLIPS, payload: false });
            if (error?.response?.data?.responsemessage) {
                toast.error(error?.response?.data?.responsemessage);
                return false;
            }
            toast.error(error.message);
            console.log('eroror', error);
            return false;
        }
    };
}
// Action to reset the betslips
export function handleResetBetSlips() {
    return async (dispatch, getState) => {
        await dispatch({ type: RESET_BET_SLIPS, payload: [] });
        await dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: {} });
    };
}
// Action to remove a betslip
export function handleRemoveBetSlip(slipID, lotteryId) {
    return async (dispatch, getState) => {
        const { lotteryData } = getState();

        let slips = lotteryData?.betSlips;
        slips = slips?.filter((item, idx) => idx !== slipID);

        let potentialPayload = {};
        potentialPayload.lotteryId = lotteryId;
        potentialPayload.betSlips = JSON.stringify(slips);
        // checkExpiredUserToken();
        const token = getUserToken();
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/get-potential-winning`,
            potentialPayload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );

        dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });

        dispatch({ type: REMOVE_BET_SLIP, payload: slips });
        return toast.success('Bet slip removed Successfully');
    };
}

// Action to remove a betslip for client games
export function handleRemoveClientBetSlip(slipID, lotteryId) {
    return async (dispatch, getState) => {
        const { lotteryData } = getState();

        let slips = lotteryData?.betSlips;
        slips = slips?.filter((item, idx) => idx !== slipID);
        const clientKey = getWindowQueryString('clientKey');
        let potentialPayload = {};
        potentialPayload.lotteryId = lotteryId;
        potentialPayload.betSlips = JSON.stringify(slips);
        const res = await axios.post(
            `${process.env.NEXT_PUBLIC_BASE_URL}/tenant/get-potential-winning`,
            potentialPayload,
            {
                headers: {
                    'x-api-key': clientKey,
                },
            },
        );

        dispatch({ type: SET_POTENTIAL_WINNING_DATA, payload: res?.data?.data?.data });

        dispatch({ type: REMOVE_BET_SLIP, payload: slips });
        return toast.success('Bet slip removed Successfully');
    };
}

// Action to set the combo description and set it to redux state
export function handleSetComboDescription(betType, booster, resultType) {
    return async (dispatch, getState) => {
        try {
            const lotteryCategory = dispatch(handleGetLotteryCategory());

            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/ticket/fetch-combo-description?category=${lotteryCategory}&betType=${betType}&booster=${booster}&resultType=${resultType}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            let description = res.data?.data?.data?.description;

            dispatch({
                type: SET_COMBO_DESCRIPTION,
                payload: description,
            });
        } catch (error) {
            console.log('err', error); // responsecode: 404
            if (error?.response?.data?.responsecode === 404) {
                dispatch({
                    type: SET_COMBO_DESCRIPTION,
                    payload: 'No Description for this Combination Found',
                });
            }
        }
    };
}

// Action to set the combo description and set it to redux state
export function handleReSetComboDescription() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: RESET_COMBO_DESCRIPTION,
                payload: '',
            });
        } catch (error) {
            console.log('err', error); // responsecode: 404
        }
    };
}
