export const KenoAditionalOptions = [
    {
        description: '',
        isSetAB: false,
        max: 1,
        min: 1,
        name: '1-no-draw',
        number: 11,
        value: '1-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 2,
        min: 2,
        name: '2-no-draw',
        number: 11,
        value: '2-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 3,
        min: 3,
        name: '3-no-draw',
        number: 11,
        value: '3-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 4,
        min: 4,
        name: '4-no-draw',
        number: 11,
        value: '4-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 5,
        min: 5,
        name: '5-no-draw',
        number: 11,
        value: '5-no-draw',
    },

    {
        description: '',
        isSetAB: false,
        max: 6,
        min: 6,
        name: '6-no-draw',
        number: 11,
        value: '6-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 7,
        min: 7,
        name: '7-no-draw',
        number: 11,
        value: '7-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 8,
        min: 8,
        name: '8-no-draw',
        number: 11,
        value: '8-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 9,
        min: 9,
        name: '9-no-draw',
        number: 11,
        value: '9-no-draw',
    },

    {
        description: '',
        isSetAB: false,
        max: 10,
        min: 10,
        name: '10-no-draw',
        number: 11,
        value: '10-no-draw',
    },
];
export const Six49AditionalOptions = [
    {
        description: '',
        isSetAB: false,
        max: 1,
        min: 1,
        name: '1-bet-zero',
        number: 11,
        value: '1-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 2,
        min: 2,
        name: '2-bet-zero',
        number: 11,
        value: '2-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 3,
        min: 3,
        name: '3-bet-zero',
        number: 11,
        value: '3-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 4,
        min: 4,
        name: '4-bet-zero',
        number: 11,
        value: '4-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 5,
        min: 5,
        name: '5-bet-zero',
        number: 11,
        value: '5-no-draw',
    },

    {
        description: '',
        isSetAB: false,
        max: 6,
        min: 6,
        name: '6-bet-zero',
        number: 11,
        value: '6-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 7,
        min: 7,
        name: '7-bet-zero',
        number: 11,
        value: '7-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 8,
        min: 8,
        name: '8-bet-zero',
        number: 11,
        value: '8-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 9,
        min: 9,
        name: '9-bet-zero',
        number: 11,
        value: '9-no-draw',
    },

    {
        description: '',
        isSetAB: false,
        max: 10,
        min: 10,
        name: '10-bet-zero',
        number: 11,
        value: '10-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 15,
        min: 15,
        name: '15-bet-zero',
        number: 11,
        value: '15-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 20,
        min: 20,
        name: '20-bet-zero',
        number: 11,
        value: '20-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 1,
        min: 1,
        name: '*1-bet-zero*',
        number: 11,
        value: '*1-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 2,
        min: 2,
        name: '*2-bet-zero*',
        number: 11,
        value: '*2-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 3,
        min: 3,
        name: '*3-bet-zero*',
        number: 11,
        value: '*3-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 4,
        min: 4,
        name: '*4-bet-zero*',
        number: 11,
        value: '*4-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 5,
        min: 5,
        name: '*5-bet-zero*',
        number: 11,
        value: '*5-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 6,
        min: 6,
        name: '*6-bet-zero*',
        number: 11,
        value: '*6-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 7,
        min: 7,
        name: '*7-bet-zero*',
        number: 11,
        value: '*7-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 8,
        min: 8,
        name: '*8-bet-zero*',
        number: 11,
        value: '*8-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 9,
        min: 9,
        name: '*9-bet-zero*',
        number: 11,
        value: '*9-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 10,
        min: 10,
        name: '*10-bet-zero*',
        number: 11,
        value: '*10-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 15,
        min: 15,
        name: '*15-bet-zero*',
        number: 11,
        value: '*15-bet-zero*',
    },
    {
        description: '',
        isSetAB: false,
        max: 20,
        min: 20,
        name: '*20-bet-zero*',
        number: 11,
        value: '*20-bet-zero*',
    },
];
export const MetroAditionalOptions = [
    {
        description: '',
        isSetAB: false,
        max: 1,
        min: 1,
        name: '1-no-draw',
        number: 11,
        value: '1-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 2,
        min: 2,
        name: '2-no-draw',
        number: 11,
        value: '2-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 3,
        min: 3,
        name: '3-no-draw',
        number: 11,
        value: '3-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 4,
        min: 4,
        name: '4-no-draw',
        number: 11,
        value: '4-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 5,
        min: 5,
        name: '5-no-draw',
        number: 11,
        value: '5-no-draw',
    },
];
export const EazywinAditionalOptions = [
    {
        description: '',
        isSetAB: false,
        max: 1,
        min: 1,
        name: '1-no-draw',
        number: 11,
        value: '1-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 2,
        min: 2,
        name: '2-no-draw',
        number: 11,
        value: '2-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 3,
        min: 3,
        name: '3-no-draw',
        number: 11,
        value: '3-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 4,
        min: 4,
        name: '4-no-draw',
        number: 11,
        value: '4-no-draw',
    },
    {
        description: '',
        isSetAB: false,
        max: 5,
        min: 5,
        name: '5-no-draw',
        number: 11,
        value: '5-no-draw',
    },
];
